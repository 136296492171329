:root {
  --bg-color: #1e1e1e;
  --text-color: #e0e0e0;
  --input-bg: #2d2d2d;
  --button-bg: #4a4a4a;
  --button-hover: #5a5a5a;
  --border-color: #3a3a3a;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
}

.gif-processor {
  max-width: 1200px;
  margin: 0 auto;
}

.input-group {
  margin-bottom: 15px;
}

label {
  display: inline-block;
  width: 120px;
  margin-right: 10px;
}

input[type="file"],
input[type="number"] {
  background-color: var(--input-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 5px 10px;
  border-radius: 4px;
}

button {
  background-color: var(--button-bg);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover:not(:disabled) {
  background-color: var(--button-hover);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.processing {
  margin-top: 10px;
  font-style: italic;
}

.gif-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.gif-box {
  width: 48%;
}

.gif-box img {
  max-width: 100%;
  height: auto;
}

.memory-saved {
  margin-top: 20px;
  font-weight: bold;
}

/* Add this to your existing CSS */

.gif-box p {
  margin-top: 10px;
  font-size: 0.9em;
}

.download-section {
  margin-top: 20px;
  text-align: center;
}

.memory-saved {
  margin-top: 20px;
  background-color: var(--input-bg);
  padding: 15px;
  border-radius: 4px;
}

.memory-saved p {
  margin: 5px 0;
}